import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { LoadingSpinner } from 'components/shared/loading-spinner';
import { Body } from './body';
import { useUserMemberTracking } from 'hooks/admin/useMemberTracking';

export const MembersChart = () => {
  const { school } = useSchoolContext();
  const memberTracking = useUserMemberTracking(school.id);

  if (!memberTracking.data) {
    return (
      <CardBase sx={{ height: 250 }}>
        <LoadingSpinner />
      </CardBase>
    );
  }

  return <Body memberTracking={memberTracking.data} />;
};

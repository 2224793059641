import { Avatar, Box, SxProps } from '@mui/material';
import { green, grey } from '@mui/material/colors';

type ImageProps = {
  picture?: string;
  firstName: string;
  selected?: boolean;
  size: number | string;
  sx?: SxProps;
};

export const DisplayImage = ({ picture, firstName, size, selected, sx }: ImageProps) => {
  return (
    <Box
      sx={{
        borderRadius: '50%',
        boxShadow: `1px 2px 2px ${grey[500]}`,
        width: size,
        height: size,
        ...(selected && { outline: 'solid 3px', outlineColor: green['A700'] }),
        ...sx,
      }}
    >
      {picture ? (
        <Avatar
          src={picture}
          sx={{
            width: size,
            height: size,
          }}
          imgProps={{ referrerPolicy: 'no-referrer' }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: size,
            height: size,
            backgroundColor: `hsl(${(firstName.charAt(0).toLowerCase().charCodeAt(0) - 96) * (360 / 26)},100%,50%)`,
            borderRadius: '50%',
          }}
        >
          <Box sx={{ m: 'auto', pt: 0.5, fontSize: 23 }}>{`${firstName.charAt(0)}`}</Box>
        </Box>
      )}
    </Box>
  );
};

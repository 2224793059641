import { Attendance } from 'hooks/useAttendance';
import { Membership } from 'hooks/useMemership';
import { DateTime, Interval } from 'luxon';

// global time
// const utc = DateTime.now().toUTC().toString();

// DateTime with timezone
// console.log('iso', DateTime.now().toISO());

// convert from utc to local
// console.log('from utc', iso(utc).toString());

export const friendlyDate = (dateTime: string): string =>
  DateTime.fromISO(dateTime).toLocaleString({
    weekday: 'long',
    month: 'long',
    day: '2-digit',
  });
export const dateFormat = (date: string): string => DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);

export const millis = (ts: number) => DateTime.fromMillis(ts);
export const iso = (date: string) => DateTime.fromISO(date);
export const isoDate = (date: string) => DateTime.fromISO(date).toISODate();
export const toIso = (date: string) => DateTime.fromISO(date).toISO();
export const dayTime = (dateTime: string): number => {
  const date = DateTime.fromISO(dateTime);
  return iso(date.toISOTime()).toMillis();
};
export const getAge = (date: string | null) =>
  date ? Math.trunc(Number(Interval.fromDateTimes(DateTime.fromISO(date), DateTime.now()).length('years')) || 0) : 0;

export const utc = (date: string) => DateTime.fromISO(date).toUTC().toString();

export const friendlyTime = (dateTime: string): string => DateTime.fromISO(dateTime).toFormat('h:mma').toLowerCase();

export const minutesBetween = (start: DateTime, end: DateTime) => Interval.fromDateTimes(start, end).count('minutes');

export const daysBetween = (start: string, end: string) =>
  Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end)).count('days');

export const duration = (totalMinutes: number): string => {
  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours >= 1 ? ` ${hours}hr` : ''} ${minutes >= 1 ? `${minutes}min ` : ``}`;
};

export const dayName = (date: string) => DateTime.fromISO(date).toLocaleString({ weekday: 'long' }).toLowerCase();

export const dateRange = (start: string, end: string) => {
  if (start === end) return [start];
  return Interval.fromDateTimes(iso(start), iso(end))
    .splitBy({ days: 1 })
    .map(d => d.start.toISODate());
};

export const endDate = (membership: Membership) => {
  if (membership.endDate) {
    return `Ends ${iso(membership.endDate).toFormat('dd/MM/yyyy')}`;
  } else if (membership.limit) {
    const plural = membership.frequency * membership.limit > 1 ? 's' : '';
    return `Ends ${membership.frequency * membership.limit} ${membership.interval}${plural} after signup`;
  }
  return;
};

export const validateAttendanceWindow = (date: string, attendances: Attendance[] = [], membership: Membership) => {
  const windowStart = iso(date).minus({ [membership.interval]: 1 });
  const result = attendances.reduce((acc, a) => {
    windowStart.toMillis() < iso(a.date).toMillis() && acc++;
    console.log(a.date, a.date < windowStart.toISODate());
    return acc;
  }, 0);

  return result < membership.attendanceLimit;
};

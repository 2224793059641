import { UserContextProvider } from '../.././components/context/user-context';
import { Grid } from '@mui/material';
import { Header } from '../.././components/header';
import { PageType } from '../.././utils/types';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SchoolContextProvider } from '../../components/context/school-context';
import { grey } from '@mui/material/colors';
import { useQueryState } from '../.././hooks/useQueryState';
import { Admin } from 'components/reporting';
import { BusinessSettings } from 'components/admin/business-settings';
import { UserSettings } from 'components/user-settings';
import { MemberSettings } from 'components/admin/member-settings';
import { BrandingContextProvider } from 'components/context/branding-context';
import { Home } from 'components/home';
import { useParams } from 'react-router-dom';
import { Navbar } from 'components/navbar';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Pricing } from 'components/admin/pricing';
import { PaymentAlert } from 'components/context/school-context/payment-alert';

const clientID = String(process.env.REACT_APP_GOOGLE_CLIENT_ID);

// npm NOVU for notifications //

export const WebApp = () => {
  const { uuid } = useParams();
  const { small } = useCustomTheme();
  const [{ page = PageType.home }] = useQueryState();

  return (
    <GoogleOAuthProvider clientId={clientID}>
      <BrandingContextProvider>
        <UserContextProvider>
          <Grid container height={'100vh'} overflow={'hidden'} sx={{ background: grey[100] }}>
            <SchoolContextProvider schoolId={uuid}>
              <Header />
              <Grid ml={!small ? '180px' : ''} container height={'94vh'}>
                {page === PageType.home && <Home />}
                {page === PageType.user_settings && <UserSettings />}
                {page === PageType.reporting && <Admin />}
                {page === PageType.business_settings && <BusinessSettings />}
                {page === PageType.member_settings && <MemberSettings />}
                {page === PageType.pricing && <Pricing />}
              </Grid>
              <PaymentAlert />
              <Navbar />
            </SchoolContextProvider>
          </Grid>
        </UserContextProvider>
      </BrandingContextProvider>
    </GoogleOAuthProvider>
  );
};

import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AuthProvider } from 'utils/types';
import { Attendance } from './useAttendance';
import { Member } from './useMember';

type EmergencyContact = {
  name: string;
  phone: string;
} | null;
export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string | null;
  picture: string;
  phone: string | null;
  emergencyContact: EmergencyContact;
  password: string;
  provider: AuthProvider;
  attendance: Attendance[];
  children: User[];
  members: Member[];
};

export const useUser = (session: boolean) => {
  const { data, isLoading, isError } = useQuery<User, AxiosError>(
    ['user', session],
    async () => {
      const response = await axios.get('/user');
      return response.data;
    },
    {
      enabled: session,
    }
  );

  return { user: data, isLoading, isError };
};

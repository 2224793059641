import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MembershipType } from 'components/memberships/membership-card';

import { StatusChip } from 'components/memberships/status';
import { Enrolment } from 'hooks/useMember';
import { Membership } from 'hooks/useMemership';
import { useMemo } from 'react';
import { dateFormat } from 'utils/time';
import { Status } from 'utils/types';

type Props = {
  enrolment: Enrolment;
  membership: Membership;
  groupOwner?: boolean;
  children?: React.ReactNode;
};

export const Details = ({ enrolment, membership, groupOwner, children }: Props) => {
  const statusText = useMemo(() => {
    if (enrolment.status === Status.TRIALING && enrolment.resumesAt) return `${dateFormat(enrolment.resumesAt)}`;
    if (enrolment.cancelDate) return `${dateFormat(enrolment.cancelDate)}`;
    if (enrolment.resumesAt) return `until ${dateFormat(enrolment.resumesAt)}`;
    if (enrolment.endDate) return `until ${dateFormat(enrolment.endDate)}`;
    return '';
  }, [enrolment]);

  return (
    <>
      <Box sx={{ display: 'flex', borderBottom: `solid 1px ${grey[300]}`, pb: 3 }} justifyContent={'space-between'}>
        <Box>
          <Typography variant="h3" mb={1}>
            {membership.name}
          </Typography>
          <Typography key={membership.type} variant="h4">
            {MembershipType[membership.type]}
          </Typography>
          <Typography variant="h5">
            {membership.type.includes('membership')
              ? ' unlimited sessions'
              : ` ${membership.attendanceLimit - enrolment.attendance} sessions remaining`}
          </Typography>
          {!groupOwner ? (
            <Typography variant="h5" color={'primary.main'}>
              {`Managed by ${
                enrolment.member
                  ? `${enrolment.member?.user.firstName} ${enrolment.member?.user.lastName}`
                  : 'another account'
              }`}
            </Typography>
          ) : (
            <>
              {membership.type.includes('membership') ? (
                <Box>
                  <Typography variant="h5" sx={{ mr: 2 }}>
                    {`$${(enrolment.price / 100).toFixed(2)} ${enrolment.billingCycle || ''}`}
                  </Typography>
                </Box>
              ) : (
                <Box display={'flex'}>
                  {enrolment.price !== enrolment.actualPrice && (
                    <Typography variant="h5" sx={{ textDecoration: 'line-through', color: grey[500], mr: 2 }}>
                      {`$${(enrolment.price / 100).toFixed(2)}`}
                    </Typography>
                  )}
                  <Typography variant="h5" sx={{}}>
                    {`$${(enrolment.actualPrice / 100).toFixed(2)} ${enrolment.billingCycle || ''}`}
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Box sx={{ pt: 2 }}>
            <StatusChip status={enrolment.status}>{statusText}</StatusChip>
          </Box>
        </Box>
        {children}
      </Box>
      {!!enrolment.futureDiscounts.length && (
        <Box width={1} sx={{ borderBottom: `solid 1px ${grey[300]}`, pb: 2 }}>
          <Typography variant="h5" mt={1}>
            Discounts applied to next payment
          </Typography>
          {enrolment.futureDiscounts.map(i => (
            <Typography key={i} color={'primary.main'} variant="h5" sx={{}}>
              {i}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

import { useToken } from 'hooks/useToken';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Kiosk, useKiosk } from 'hooks/useKiosk';
import { Session } from 'hooks/useKioskSessions';
import { createCustomTheme } from 'Theme';
import { ThemeProvider } from '@mui/material';

type KioskAttributes = {
  kiosk: Kiosk;
  session: Session | undefined;
  setSession: (session?: Session) => void;
};

const KioskContext = createContext<KioskAttributes | undefined>(undefined);

export const useKioskContext = () => useContext(KioskContext) as KioskAttributes;

interface Props {
  children: JSX.Element;
}

const agent = navigator.userAgent.split(/\;|\)/g)[1];

export const KioskContextProvider = ({ children }: Props) => {
  const [session, setSession] = useState<Session>();
  const { uuid } = useParams();
  const token = useToken();
  const { kiosk } = useKiosk(token, uuid, agent);

  if (!kiosk) {
    return <>Kiosk is bound to another device</>;
  }

  const theme = createCustomTheme(kiosk.school.priColor, kiosk.school.secColor);

  return (
    <ThemeProvider theme={theme}>
      <KioskContext.Provider
        value={{
          kiosk,
          session,
          setSession: setSession,
        }}
      >
        {children}
      </KioskContext.Provider>
    </ThemeProvider>
  );
};

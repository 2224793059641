import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { LoadingSpinner } from 'components/shared/loading-spinner';
import { useTransactions } from 'hooks/admin/useTransactions';
import { Body } from './body';

export const IncomeChart = () => {
  const { school } = useSchoolContext();
  const transactions = useTransactions(school.id);

  if (!transactions.data) {
    return (
      <CardBase sx={{ m: 3, height: 250 }}>
        <LoadingSpinner />
      </CardBase>
    );
  }

  return <Body transactions={transactions.data} />;
};

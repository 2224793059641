import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { PageType, Tabs } from '../../utils/types';
import { useEffect, useRef, useState } from 'react';
import { useSchoolContext } from '../context/school-context';
import { useQueryState } from '../../hooks/useQueryState';
import { grey } from '@mui/material/colors';
import { TabButton } from './tab-button';
import logo from '../../assets/mm-logo.svg';
import { shadeColor, THEME } from 'Theme';
import { useUserContext } from 'components/context/user-context';
import { DisplayImage } from 'components/shared/display-image';
import { useBrandingContext } from 'components/context/branding-context';

export const Header = () => {
  const [query, setQuery] = useQueryState();
  const { secColor } = useBrandingContext();
  const { school } = useSchoolContext();
  const { user } = useUserContext();
  const [menu, setMenu] = useState(false);
  const schoolRef = useRef(null);

  useEffect(() => {
    const defaultTab = Tabs[query.page as PageType]?.[0];
    if (!query.page) {
      setQuery({ page: PageType.home });
    }
    if (defaultTab && !query[`${query.page} tab`]) {
      setQuery({ [`${query.page} tab`]: defaultTab });
    }
  });

  const handleSchoolChange = (uuid = '') => {
    location.href = `${location.origin}/${uuid}`;
    setMenu(false);
  };

  return (
    <Grid container maxHeight={'9.5vh'}>
      <Grid
        container
        sx={{
          boxShadow: `1px 1px 2px ${grey[500]}`,
          background: `linear-gradient(to right, ${secColor}, ${shadeColor(secColor, -60)})`,
          zIndex: 999,
        }}
      >
        <Grid container minHeight={'6vh'}>
          <Box width={'6vh'} display={'flex'}>
            <Avatar
              ref={schoolRef}
              onClick={() => setMenu(true)}
              src={school.logo || logo}
              sx={{ m: 'auto', width: '5vh', height: '5vh', boxShadow: `1px 2px 2px ${grey[500]}` }}
            />
            {user.members.length > 1 && (
              <Menu
                id="user-menu"
                anchorEl={schoolRef.current}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menu}
                onClick={() => setMenu(false)}
              >
                {user.members.map(m => (
                  <MenuItem
                    key={m.school?.id}
                    sx={{ minWidth: '150px', display: 'flex' }}
                    onClick={() => handleSchoolChange(m.school?.uuid)}
                  >
                    <Avatar
                      src={m.school?.logo}
                      sx={{ width: '4vh', height: '4vh', mr: 2, boxShadow: `1px 2px 2px ${grey[500]}` }}
                    />
                    {m.school?.name}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
          <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
            <Typography variant="h2" m={'auto'} color={THEME.palette.getContrastText(school.secColor)}>
              {query.page}
            </Typography>
          </Box>
          <Box width={'6vh'} display={'flex'}>
            <DisplayImage
              key={user.id}
              sx={{ marginY: 'auto', zIndex: user.id, m: 'auto' }}
              firstName={user.firstName}
              picture={user.picture}
              size={'5vh'}
            />
          </Box>
        </Grid>
      </Grid>
      {!!Tabs[query.page as PageType]?.length && (
        <Grid container justifyContent={'center'} height={'4vh'} mb={'-4vh'} zIndex={99}>
          <Box width={1} bgcolor={'transparent'} display={'flex'} justifyContent={'center'}>
            {Tabs[query.page as PageType]?.map(t => (
              <TabButton
                key={t}
                onClick={() => setQuery({ [`${query.page} tab`]: t })}
                name={t}
                activeTab={query[`${query.page} tab`]}
              />
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

import { Card, CardActionArea } from '@mui/material';
import { grey } from '@mui/material/colors';
import { THEME } from 'Theme';
import { useSchoolContext } from 'components/context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';

type Props = {
  name: string;
  onClick: () => void;
  activeTab: string;
};

export const TabButton = ({ activeTab, name, onClick }: Props) => {
  const { school } = useSchoolContext();
  const { small } = useCustomTheme();
  const tabWidth = small ? '23vw' : '12vw';

  const styles = activeTab === name ? { bgcolor: school.priColor, color: 'white' } : { bgcolor: grey[50] };

  return (
    <Card
      sx={{
        ...styles,
        color: THEME.palette.getContrastText(styles.bgcolor),
        my: 1,
        mx: 0.5,
        width: tabWidth,
        display: 'flex',
      }}
    >
      <CardActionArea onClick={onClick} sx={{ textAlign: 'center', fontWeight: 550, fontSize: 12 }}>
        {name}
      </CardActionArea>
    </Card>
  );
};

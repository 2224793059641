import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { MemberTracking } from 'hooks/admin/useMemberTracking';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMemo } from 'react';
import { Chart } from 'react-charts';
import { AxisOptions } from 'react-charts/types/types';
import { iso } from 'utils/time';

type Datum = { date: string; value: number };
type Props = {
  memberTracking: MemberTracking[];
};

export const Body = ({ memberTracking }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const primaryAxis = useMemo(
    (): AxisOptions<Datum> => ({
      getValue: datum => datum?.date,
      minBandSize: 15,
      innerBandPadding: 0,
      outerBandPadding: 0,
      show: true,
      formatters: {
        scale: (v: string) =>
          iso(v).toLocaleString({
            month: 'short',
            day: '2-digit',
          }),
      },
      showGrid: false,
    }),
    []
  );
  const secondaryAxes = useMemo(
    (): AxisOptions<Datum>[] => [
      {
        elementType: 'line',
        getValue: datum => datum?.value,
        min: 0,
        formatters: { scale: (v: number) => `${v}` },
      },
    ],
    []
  );

  const memberTrackingData = [
    { label: 'active members', data: memberTracking.map(i => ({ date: i.date, value: i.active })) },
    { label: 'inactive members', data: memberTracking.map(i => ({ date: i.date, value: i.inactive })) },
    {
      label: 'prospective members',
      data: memberTracking.map(i => ({ date: i.date, value: i.prospect })),
    },
  ];

  return (
    <CardBase sx={{ p: 3, height: 250, width: 'fit-content', m: 3 }}>
      <Box height={200} maxWidth={600} width={small ? '90vw' : '60vw'} m={'auto'} mt={1}>
        <Typography mb={1} color={grey[600]}>
          {'Members'}
        </Typography>
        <Chart
          options={{
            defaultColors: [school.priColor, '#ffaf02', '#d000ff'],
            data: memberTrackingData,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </Box>
    </CardBase>
  );
};

import { Card, CardActionArea, Box, Typography, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DisplayImage } from '../display-image';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Member } from 'hooks/useMember';
import { StatusColor, statusText, Status as StatusType } from 'utils/types';
import { Status } from '../status';
import { daysBetween } from 'utils/time';
import { DateTime } from 'luxon';

type Props = {
  member: Member;
  onClick: () => void;
  sx?: SxProps;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UserButton = ({ member: { user, enrolments, lastAttendance }, onClick, sx = {} }: Props) => {
  const { cardWidth } = useCustomTheme();
  const days = daysBetween(lastAttendance, DateTime.now().toISO()) - 1;
  const dayStyle = (num: number) => {
    if (num < 8) return 'success.main';
    if (num < 15) return 'warning.main';
    if (num >= 15) return 'error';
  };

  return (
    <Card
      sx={{
        width: cardWidth,
        boxShadow: `0.5px 1px 3px ${grey[400]}`,
        height: 70,
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick} sx={{ p: 2, height: 70 }}>
        <Box height={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box display={'flex'} width={'75%'} overflow={'hidden'}>
            <DisplayImage firstName={user.firstName} picture={user.picture} size={40} sx={{ my: 'auto' }} />
            <Box my={'auto'} width={'80%'}>
              <Typography ml={2} my={'auto'} variant="h3" noWrap>
                {`${user.firstName} ${user.lastName}`}
              </Typography>
              <Box display={'flex'} ml={1}>
                {enrolments
                  .filter(e => e.status !== StatusType.CANCELED)
                  .map(e => (
                    <Status
                      key={e.membershipId}
                      severity={StatusColor[e.status]}
                      height={22}
                      sx={{ pl: 0, ml: 1, mt: 2, pr: 2 }}
                    >
                      <Box mt={'-7px'}>{statusText[e.status]}</Box>
                    </Status>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '30%', borderLeft: `1px solid ${grey[300]}`, pl: 1 }}>
            <Box m={'auto'}>
              {days >= 0 ? (
                <Box>
                  <Typography textAlign={'center'} variant="h5">
                    Last visit
                  </Typography>
                  <Typography color={dayStyle(days)} textAlign={'center'}>{`${days} days ago`}</Typography>
                </Box>
              ) : (
                <Typography textAlign={'center'} variant="h5">
                  No attendance
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

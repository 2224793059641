import { Box, ButtonBase, Typography } from '@mui/material';
import { Session } from 'hooks/useClass';
import { friendlyTime } from '../../../utils/time';
import { useUserContext } from 'components/context/user-context';
import { CardBase } from 'components/shared/card-base';
import { DisplayImage } from 'components/shared/display-image';
import { useSchoolContext } from 'components/context/school-context';

type Props = {
  classes: Session;
  handleClick: (c: Session) => void;
  closure?: string;
};

export const ClassCard = ({ classes: c, handleClick, closure }: Props) => {
  const { school } = useSchoolContext();
  const { users } = useUserContext();
  const registeredUsers = users.filter(i => c.attendance.includes(i.id));

  return (
    <Box sx={{ display: 'flex', mb: 4 }} key={c.id}>
      <ButtonBase disabled={!!closure} sx={{ height: 60, width: 1, borderRadius: 6 }} onClick={() => handleClick(c)}>
        {closure && (
          <Box
            display={'flex'}
            height={1}
            width={1}
            position={'absolute'}
            sx={{ backgroundColor: '#e9e9e9d2', borderRadius: 6 }}
          >
            <Typography variant="h2" margin={'auto'}>
              {closure}
            </Typography>
          </Box>
        )}
        {registeredUsers.length ? (
          <CardBase
            sx={{
              height: 60,
              backgroundColor: school.priColor,
              display: 'flex',
            }}
          >
            <Box sx={{ display: 'flex', width: 0.88 }}>
              <Box
                sx={{
                  my: 'auto',
                  mx: 2,
                }}
              >
                <Typography variant="h1" color={'white'}>
                  &#10003;
                </Typography>
              </Box>
              <Typography
                color={'white'}
                maxWidth={0.8}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
                my={'auto'}
                variant="h4"
                textAlign={'start'}
              >
                {`${c.subject.map(i => i.name).join(', ')}`}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', marginRight: 2, marginLeft: 4 }}>
              {registeredUsers.map(user => (
                <DisplayImage
                  key={user.id}
                  sx={{ marginY: 'auto', marginLeft: -4, zIndex: user.id }}
                  firstName={user.firstName}
                  picture={user.picture}
                  size={30}
                />
              ))}
            </Box>
          </CardBase>
        ) : (
          <CardBase sx={{ height: 60 }}>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: 18,
                  mr: 2,
                }}
              >
                {c.subject.map(i => (
                  <Box key={i.id} sx={{ height: 60 / c.subject.length, backgroundColor: i.color }} />
                ))}
              </Box>
              <Box my={'auto'} maxWidth={'90%'}>
                <Typography
                  variant="h3"
                  textAlign={'start'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
                >
                  {`${c.subject.map(i => i.name).join(', ')}`}
                </Typography>
                <Box display={'flex'}>
                  <Typography variant="body2" textAlign={'start'} mr={1}>
                    {friendlyTime(c?.dateTime).toUpperCase()}
                  </Typography>
                  <Typography variant="body2" textAlign={'start'} sx={{ color: c.location.color, fontWeight: 600 }}>
                    {c.location.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardBase>
        )}
      </ButtonBase>
    </Box>
  );
};

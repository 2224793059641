import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import EditIcon from '@mui/icons-material/Edit';
import { useMutate } from 'hooks/useMutate';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { useMemo, useState } from 'react';
import { SetupKiosk } from 'components/modals/setup-kiosk-modal';
import logo from '../../../../assets/mm-logo.svg';
import { ColorPicker } from 'components/modals/color-picker';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { UpdateMemberDto } from 'utils/types';
import { User } from 'hooks/useUser';
import { useUserContext } from 'components/context/user-context';
import { Status } from 'components/shared/status';

export const SchoolSettings = () => {
  const { school, account } = useSchoolContext();
  const { user } = useUserContext();
  const membersResponse = useSchoolMembers(school.id);
  const { create: update } = useMutate<UpdateMemberDto>('admin/member', {
    invalidate: ['members'],
  });
  const { cardWidth } = useCustomTheme();
  const [schoolName, setSchoolName] = useState(school.name);
  const [schoolEmail, setSchoolEmail] = useState(school.email);
  const [priColor, setPriColor] = useState(school.priColor);
  const [secColor, setSecColor] = useState(school.secColor);
  const [colorPickers, setColorPickers] = useState({ pri: false, sec: false });
  const [setupKiosk, setSetupKiosk] = useState(false);
  const { create: uploadLogo } = useMutate('/school/update-logo', { onSuccess: () => window.location.reload() });
  const { create } = useMutate('/school/update', { onSuccess: () => window.location.reload() });
  const { create: requestLink } = useMutate('payment/link', {
    onSuccess: response => (location.href = response as string),
    headers: {
      href: location.href,
    },
  });
  const members = useMemo(
    () => membersResponse?.data?.filter(m => m.user.email && m.userId !== user.id) || [],
    [membersResponse.data]
  );

  const accountValid = account.charges_enabled && account.payouts_enabled;

  const handleSubmit = () => {
    const data = {
      name: schoolName,
      email: schoolEmail,
      priColor: priColor,
      secColor: secColor,
    };
    create(data);
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('logo', file);
    uploadLogo(formData);
  };

  const setPickers = (picker?: 'pri' | 'sec') => {
    if (!picker) setColorPickers({ pri: false, sec: false });
    else setColorPickers({ pri: picker === 'pri', sec: picker === 'sec' });
  };

  const handleSelect = (memberUser: User, isAdmin: boolean) => {
    update({ userId: memberUser.id, schoolId: school.id, isAdmin, user: memberUser });
  };

  return (
    <>
      <SetupKiosk open={setupKiosk} onClose={() => setSetupKiosk(false)} />
      <Grid container justifyContent={'center'} alignContent={'center'}>
        <Box sx={{ my: 3, width: cardWidth }}>
          <Grid container mb={3} justifyContent={'center'}>
            <CardMedia style={{ width: 100, borderRadius: 16 }} component={'img'} src={school.logo || logo} />
            <Box mt={6} ml={6} width={100}>
              <Box display={'flex'} width={'100%'}>
                <Typography m={'auto'} variant="h3">
                  School logo
                </Typography>
              </Box>
              <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <ImageUploadModal logoColor={school.priColor} onSuccess={handleUpload} />
              </Box>
            </Box>
          </Grid>
          <TextField
            label={`Business name`}
            value={schoolName}
            onChange={e => setSchoolName(e.target.value)}
            onClick={() => setPickers()}
          />
          <TextField
            type="email"
            label={`Business email`}
            value={schoolEmail}
            onChange={e => setSchoolEmail(e.target.value)}
            onClick={() => setPickers()}
          />
          <Box sx={{ my: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Status severity={accountValid ? 'success' : 'warning'}>
              <Box mt={'-1px'}>{account.id}</Box>
            </Status>
            <Button variant="contained" onClick={() => requestLink({})}>
              Configure Stripe
            </Button>
          </Box>
          <TextField
            label={`Brand color`}
            value={priColor}
            onChange={e => setPriColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPickers('pri')}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker open={colorPickers.pri} color={priColor} setColor={setPriColor} onClose={setPickers} />
                  <ButtonBase onClick={() => setPickers('pri')}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: priColor }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label={`Secondary color`}
            value={secColor}
            onChange={e => setSecColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPickers('sec')}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker open={colorPickers.sec} color={secColor} setColor={setSecColor} onClose={setPickers} />
                  <ButtonBase onClick={() => setPickers('sec')}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: secColor }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            select
            label={'Admins'}
            SelectProps={{
              multiple: true,
              value: members.filter(m => m.isAdmin).map(m => m.userId),
              MenuProps: {
                sx: { maxHeight: 190 },
              },
            }}
          >
            {members.map(m => (
              <MenuItem onClick={() => handleSelect(m.user, !m.isAdmin)} key={m.userId} value={m.userId}>
                {`${m.user.firstName} ${m.user.lastName}`}
              </MenuItem>
            ))}
          </TextField>
          <Box display={'flex'} justifyContent={'space-between'} mt={4}>
            <Button variant="contained" onClick={() => setSetupKiosk(true)}>
              Configure kiosk
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

import { Card, Grid, Typography, TextField, Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { DateTime } from 'luxon';
import { useState, useMemo } from 'react';
import { iso } from 'utils/time';
import { CreateUserDto } from 'utils/types';

type Props = { user?: User; open: boolean; onClose: () => void };

export const ChildModal = ({ user, open, onClose }: Props) => {
  const { create, destroy } = useMutate<CreateUserDto>('user/child', {
    invalidate: ['user'],
  });
  const { cardWidth } = useCustomTheme();
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [birthday, setBirthday] = useState<DateTime | null>(user?.birthday ? iso(user.birthday) : null);
  const [emergencyName, setEmergencyName] = useState(user?.emergencyContact?.name || '');
  const [emergencyPhone, setEmergencyPhone] = useState(user?.emergencyContact?.phone || '');

  const required = [firstName, lastName, birthday, emergencyName, emergencyPhone];
  const disabled = useMemo(() => required.some(i => !i), required);

  const handleCreate = () => {
    if (birthday) {
      const dto: CreateUserDto = {
        ...(user?.id && { id: user.id }),
        firstName,
        lastName,
        birthday: birthday.toISODate(),
        emergencyContact: { name: emergencyName, phone: emergencyPhone },
      };
      create(dto);
    }
    onClose();
  };

  return (
    <CustomModal open={open || !!user} onClose={onClose}>
      <Card
        sx={{
          display: 'flex',
          width: cardWidth,
          m: 'auto',
          p: 6,
          justifyItems: 'center',
        }}
      >
        <Grid container>
          <Typography variant="h3" mb={2}>
            {user ? 'Update child account' : 'Create child account'}
          </Typography>
          <TextField label={'first name'} value={firstName} onChange={e => setFirstName(e.target.value)}></TextField>
          <TextField label={'last name'} value={lastName} onChange={e => setLastName(e.target.value)}></TextField>
          <DatePicker
            label={'birthday'}
            format={'dd/MM/yyyy'}
            onChange={d => {
              setBirthday(d as DateTime);
            }}
            value={birthday}
          />
          <TextField
            label={'emergency contact name'}
            value={emergencyName}
            onChange={e => setEmergencyName(e.target.value)}
          />
          <TextField
            label={'emergency contact phone'}
            value={emergencyPhone}
            onChange={e => setEmergencyPhone(e.target.value)}
          />
          <Box mt={4} width={1} display={'flex'} justifyContent={'space-between'}>
            <Box>
              {user && (
                <Button
                  variant="outlined"
                  sx={{ justifySelf: 'start' }}
                  onClick={() => {
                    destroy(user.id);
                    close();
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box>
              <Button variant="contained" disabled={disabled} sx={{ ml: 6 }} onClick={() => handleCreate()}>
                {user ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Card>
    </CustomModal>
  );
};

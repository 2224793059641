import { Grid, TextField, Box, Button, CardMedia, Typography, Snackbar, Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useSchoolContext } from 'components/context/school-context';
import { useUserContext } from 'components/context/user-context';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMutate } from 'hooks/useMutate';
import { DateTime } from 'luxon';
import { useState, useMemo } from 'react';
import { iso } from 'utils/time';
import { CreateUserDto } from 'utils/types';

export const PersonalSettings = () => {
  const { school } = useSchoolContext();
  const [save, setSave] = useState(false);
  const { update } = useMutate<CreateUserDto>('user', { onSuccess: () => setSave(true), invalidate: ['user'] });
  const { cardWidth } = useCustomTheme();
  const { user } = useUserContext();
  const { create: updatePicture } = useMutate<CreateUserDto>('user/upload-picture', {
    invalidate: ['user'],
    headers: { targetId: user.id },
  });
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [birthday, setBirthday] = useState<DateTime | null>(user?.birthday ? iso(user.birthday) : null);
  const [phone, setPhone] = useState<string>(user?.phone || '');
  const [emergencyName, setEmergencyName] = useState(user?.emergencyContact?.name || '');
  const [emergencyPhone, setEmergencyPhone] = useState(user?.emergencyContact?.phone || '');

  const phoneRequired = user?.provider !== 'parent';
  const required = [
    firstName,
    lastName,
    birthday,
    emergencyName,
    emergencyPhone,
    user?.provider === 'parent' ? 'ok' : phone,
  ];
  const disabled = useMemo(() => required.some(i => !i), required);

  const handleCreate = () => {
    if (birthday) {
      const dto: CreateUserDto = {
        ...(user?.id && { id: user.id }),
        firstName,
        lastName,
        birthday: birthday.toISODate(),
        ...(phoneRequired && { phone }),
        emergencyContact: { name: emergencyName, phone: emergencyPhone },
      };
      update(dto);
    }
  };

  const handleUpload = (file: File) => {
    const formData = new FormData();
    formData.append('picture', file);
    updatePicture(formData);
  };

  return (
    <Grid container justifyContent={'center'} alignContent={'center'}>
      <Snackbar
        open={save}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setSave(false)}
      >
        <Alert severity="success" variant="outlined" sx={{ width: '100%' }}>
          Saved!
        </Alert>
      </Snackbar>
      <Box width={cardWidth}>
        <Grid container mb={3} justifyContent={'center'}>
          <CardMedia style={{ width: 100, borderRadius: 16 }} component={'img'} src={user.picture} />
          <Box mt={6} ml={6} width={100}>
            <Box display={'flex'} width={'100%'}>
              <Typography m={'auto'} variant="h3">
                Display picture
              </Typography>
            </Box>
            <Box display={'flex'} width={'100%'} justifyContent={'center'}>
              <ImageUploadModal logoColor={school.priColor} onSuccess={handleUpload} />
            </Box>
          </Box>
        </Grid>
        <TextField label={'first name'} value={firstName} onChange={e => setFirstName(e.target.value)}></TextField>
        <TextField label={'last name'} value={lastName} onChange={e => setLastName(e.target.value)}></TextField>
        <DatePicker
          label={'birthday'}
          format={'dd/MM/yyyy'}
          onChange={d => {
            setBirthday(d as DateTime);
          }}
          value={birthday}
        />
        {phoneRequired && <TextField label={'phone'} value={phone} onChange={e => setPhone(e.target.value)} />}
        <TextField
          label={'emergency contact name'}
          value={emergencyName}
          onChange={e => setEmergencyName(e.target.value)}
        />
        <TextField
          label={'emergency contact phone'}
          value={emergencyPhone}
          onChange={e => setEmergencyPhone(e.target.value)}
        />
        <Box mt={6} width={1} textAlign={'end'}>
          <Button variant="contained" disabled={disabled} sx={{ ml: 6 }} onClick={() => handleCreate()}>
            {'Save'}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

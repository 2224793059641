import { Button, CircularProgress } from '@mui/material';
import { useUserContext } from 'components/context/user-context';
import { DateSelectModal } from 'components/modals/date-select-modal';
import { Enrolment, UpdateEnrolmentDto } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CancelableStatus, ResumableStatus } from 'utils/types';

export const AdminUpdateButton = ({ enrolment, user }: { enrolment: Enrolment; user: User }) => {
  const queryClient = useQueryClient();
  const { user: contextUser } = useUserContext();
  const [open, setOpen] = useState(false);
  const { update } = useMutate<UpdateEnrolmentDto>('enrolment/cancel-subscription', {
    onSuccess: () => {
      queryClient.invalidateQueries(['enrolment']);
      queryClient.invalidateQueries(['member']);
    },
    headers: { impersonator: contextUser.id },
  });

  const handleUpdate = (date?: number) => {
    console.log('date', date);
    setOpen(false);
    update({
      userId: user.id,
      enrolment: enrolment,
      cancelDate: date,
    });
    enrolment.loading = true;
  };

  const handleClick = () => {
    if (CancelableStatus.includes(enrolment.status)) {
      setOpen(true);
    } else {
      handleUpdate();
    }
  };

  return (
    <>
      <DateSelectModal
        name="Cancellation date"
        buttonName="Cancel"
        open={open}
        onClose={() => setOpen(false)}
        handleUpdate={handleUpdate}
      />
      {enrolment.loading ? (
        <CircularProgress size={22} sx={{ mb: -3 }} />
      ) : (
        <Button onClick={handleClick}>
          {(enrolment.cancelDate || enrolment.resumesAt) && ResumableStatus.includes(enrolment.status)
            ? 'Resume'
            : 'Cancel'}
        </Button>
      )}
    </>
  );
};

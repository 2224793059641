import { Grid } from '@mui/material';
import { AuthWrapper } from 'components/auth-wrapper';
import { IncomeChart } from './income-chart';
import { AttendanceChart } from './attendance-chart';
import { MembersChart } from './members-chart';

export const Admin = () => {
  return (
    <AuthWrapper>
      <Grid container alignContent={'center'}>
        <Grid container height={'93vh'} overflow={'scroll'} pt={'2vh'} justifyContent={'center'}>
          <Grid container justifyContent={'center'} mb={20}>
            <MembersChart />
            <AttendanceChart />
            <IncomeChart />
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

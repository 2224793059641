import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { Transaction } from 'hooks/admin/useTransactions';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Chart } from 'react-charts';
import { AxisOptions } from 'react-charts/types/types';
import { iso } from 'utils/time';

type Datum = { date: string; value: number };
type Props = {
  transactions: Transaction[];
};

export const Body = ({ transactions }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const primaryAxis = useMemo(
    (): AxisOptions<Datum> => ({
      getValue: datum => datum?.date,
      minBandSize: 15,
      innerBandPadding: 0,
      outerBandPadding: 0,
      show: true,
      formatters: {
        scale: (v: string) =>
          iso(v).toLocaleString({
            month: 'short',
            day: '2-digit',
          }),
      },
      showGrid: false,
    }),
    []
  );
  const secondaryAxes = useMemo(
    (): AxisOptions<Datum>[] => [
      {
        elementType: 'bar',
        getValue: datum => datum?.value,
        min: 0,
        formatters: {
          scale: (v: number) => {
            return v / 100000 >= 1 ? `$${(v / 100000).toFixed(1)}k` : `$${v / 100}`;
          },
        },
      },
    ],
    []
  );

  const chargeData = transactions.filter(i => i.reporting_category === 'charge');

  const ranges = new Array(12).fill(0).map((_, i) => {
    const date = DateTime.now().minus({ months: i });
    return {
      date: date.toISODate(),
      value: sumBy(
        chargeData.filter(d => d.created <= date.toSeconds() && d.created > date.minus({ months: 1 }).toSeconds()),
        d => d.amount
      ),
    };
  });

  const chargedTransactions = {
    label: '$',
    data: ranges.reverse(),
  };

  return (
    <CardBase sx={{ p: 3, height: 250, width: 'fit-content', m: 3 }}>
      <Box height={200} maxWidth={600} width={small ? '90vw' : '60vw'} m={'auto'} mt={1}>
        <Typography mb={1} color={grey[600]}>
          {'Revenue'}
        </Typography>
        <Chart
          options={{
            defaultColors: [school.priColor, '#ffaf02', '#d000ff'],
            data: [chargedTransactions],
            primaryAxis,
            secondaryAxes,
          }}
        />
      </Box>
    </CardBase>
  );
};

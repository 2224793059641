import './App.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { THEME } from './Theme';
import { useQueryClient } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { WebApp } from 'pages/web-app';
import { KioskApp } from 'pages/kiosk-app';

const App = () => {
  const queryClient = useQueryClient();

  document.onvisibilitychange = () => {
    queryClient.invalidateQueries(['auth']);
  };

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Routes>
        <Route path="/:uuid?" element={<WebApp />} />
        <Route path="kiosk/:uuid" element={<KioskApp />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { HexColorPicker } from 'react-colorful';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutate } from 'hooks/useMutate';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { useMemo, useState } from 'react';
import { CustomModal } from 'components/shared/custom-modal';
import logo from '../../../assets/mm-logo.svg';
import { THEME } from 'Theme';
import { School } from 'hooks/useSchool';

export const CreateSchoolModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { small } = useCustomTheme();
  const [schoolName, setSchoolName] = useState('');
  const [schoolEmail, setSchoolEmail] = useState('');
  const [priColor, setPriColor] = useState('#0094A7');
  const [colorPickers, setColorPickers] = useState({ pri: false, sec: false });
  const [customLogo, setCustomLogo] = useState<string>();
  const [customLogoUrl, setCustomLogoUrl] = useState<string>();
  const [address, setAddress] = useState('');
  const { create: createLogo } = useMutate('/school/create-logo', {
    onSuccess: response => setCustomLogo(response as string),
  });
  const { create } = useMutate<School>('/school', {
    onSuccess: school =>
      (location.href = `${location.origin}/${school.uuid}?page=Business%20settings&Business%20settings%20tab=School`),
  });

  const disabled = useMemo(() => !schoolName || !schoolEmail || !address, [schoolName, schoolEmail, address]);

  const handleSubmit = () => {
    const data = {
      name: schoolName,
      email: schoolEmail,
      priColor: priColor,
      ...(!!customLogo && { logo: customLogo }),
      address,
    };
    create(data);
  };

  const handleUpload = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setCustomLogoUrl(reader.result as string), false);
    reader.readAsDataURL(file);
    const formData = new FormData();
    formData.append('logo', file);
    createLogo(formData);
  };

  const setPickers = (picker?: 'pri' | 'sec') => {
    if (!picker) setColorPickers({ pri: false, sec: false });
    else setColorPickers({ pri: picker === 'pri', sec: picker === 'sec' });
  };

  const theme = createTheme({
    ...THEME,
    ...{
      palette: {
        ...THEME.palette,
        primary: {
          main: priColor,
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CustomModal open={open} onClose={onClose}>
        <Card
          sx={{
            width: small ? '90vw' : '540px',
            m: 'auto',
            p: 6,
          }}
        >
          <Grid container justifyContent={'center'} alignContent={'center'}>
            <Box sx={{ my: 3 }}>
              <Grid container mb={3} justifyContent={'center'}>
                <CardMedia style={{ width: 100, borderRadius: 16 }} component={'img'} src={customLogoUrl || logo} />
                <Box mt={6} ml={6} width={100}>
                  <Box display={'flex'} width={'100%'}>
                    <Typography m={'auto'} variant="h3">
                      Club logo
                    </Typography>
                  </Box>
                  <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <ImageUploadModal logoColor={priColor} onSuccess={handleUpload} />
                  </Box>
                </Box>
              </Grid>
              <TextField
                label={`Club name`}
                value={schoolName}
                onChange={e => setSchoolName(e.target.value)}
                onClick={() => setPickers()}
              />
              <TextField
                type="email"
                label={`Email`}
                value={schoolEmail}
                onChange={e => setSchoolEmail(e.target.value)}
                onClick={() => setPickers()}
              />
              <TextField
                label={`Brand colour`}
                value={priColor}
                onChange={e => setPriColor(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setColorPickers(i => ({ pri: !i.pri, sec: false }))}>
                        {colorPickers.pri ? <CancelIcon /> : <EditIcon />}
                      </IconButton>
                      {colorPickers.pri ? (
                        <Box width={132}>
                          <HexColorPicker
                            style={{
                              top: 6,
                              zIndex: 99,
                              position: 'absolute',
                              height: 120,
                              width: 140,
                            }}
                            color={priColor}
                            onChange={setPriColor}
                          />
                        </Box>
                      ) : (
                        <ButtonBase onClick={() => setPickers('pri')}>
                          <Box
                            sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: priColor }}
                          />
                        </ButtonBase>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField label={'address'} value={address} onChange={e => setAddress(e.target.value)}></TextField>
              <Box display={'flex'} justifyContent={'end'} mt={4}>
                <Button disabled={disabled} variant="contained" onClick={handleSubmit}>
                  Create
                </Button>
              </Box>
            </Box>
          </Grid>
        </Card>
      </CustomModal>
    </ThemeProvider>
  );
};

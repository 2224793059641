import { useQueryState } from 'hooks/useQueryState';
import { Grid } from '@mui/material';
import { PageType, Tabs } from 'utils/types';
import { Timetable } from './timetable';
import { Closures } from './closures';

export const Home = () => {
  const [query] = useQueryState();

  const currentTab = query[`${query.page} tab`];
  const tab = (n: number): boolean => currentTab === Tabs[PageType.home][n];

  return (
    <Grid container justifyContent={'center'} height={'94.5vh'} overflow={'hidden'}>
      {tab(0) && <Timetable />}
      {tab(1) && <Closures />}
    </Grid>
  );
};

import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ModalBase } from 'components/shared/modal-base';
import { DateTime } from 'luxon';
import { useState } from 'react';

type Props = {
  name: string;
  buttonName: string;
  open: boolean;
  onClose: () => void;
  handleUpdate: (arg?: number) => void;
};

export const DateSelectModal = ({ name, buttonName, open, onClose, handleUpdate }: Props) => {
  const [date, setDate] = useState<DateTime | null>(null);

  return (
    <ModalBase title={name} open={open} onClose={onClose}>
      <Box mt={3}>
        <DatePicker
          disablePast
          label={'select date'}
          format={'dd/MM/yyyy'}
          onChange={d => {
            setDate(d as DateTime);
          }}
        />
        <Box mt={4} display={'flex'} justifyContent={'end'}>
          <Button variant="contained" onClick={() => handleUpdate(date?.toSeconds())}>
            {buttonName}
          </Button>
        </Box>
      </Box>
    </ModalBase>
  );
};

import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { useSchoolContext } from 'components/context/school-context';
import { UserButton } from 'components/shared/user-button';
import { useSchoolMember } from 'hooks/admin/useSchoolMember';
import { useMemo, useState } from 'react';
import { MemberCard } from '../member-card';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { AddButton } from 'components/shared/add-button';
import { User } from 'hooks/useUser';
import { MemberModal } from 'components/admin/member-settings/member-modal';
import { MemberFilter } from './member-filter';
import { ActiveStatus, CancelledStatus, SubscriptionParams } from 'utils/types';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { useMutate } from 'hooks/useMutate';
import { useCohorts } from 'hooks/useCohorts';
import { useMembership } from 'hooks/useMemership';
import { UpdateSubscriptionModal } from '../update-subscription-modal';

export const Members = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const { data: cohorts } = useCohorts(school.id);
  const { data: memberships } = useMembership(school.id);
  const [selectedMember, setSelectedMember] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const { data: schoolMembers } = useSchoolMembers(school.id);
  const memberResponse = useSchoolMember(school.id, selectedMember);

  const [cohortFilters, setCohortFilters] = useState<number[]>([]);
  const [membershipFilters, setMembershipFilters] = useState<number[]>([]);
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const filtersApplied = cohortFilters.length || membershipFilters.length;

  const { update } = useMutate<{ subscriptionIds: string[]; data: SubscriptionParams }>(
    'enrolment/batch-update-subscription'
  );

  const cohortfilteredMembers = useMemo(() => {
    return schoolMembers?.filter(s =>
      cohortFilters.length ? s.cohorts?.some(c => cohortFilters.includes(c?.id)) : true
    );
  }, [schoolMembers, cohortFilters]);

  const membershipfilteredMembers = useMemo(() => {
    return cohortfilteredMembers?.filter(s =>
      membershipFilters.length
        ? s.enrolments?.some(e => !CancelledStatus.includes(e.status) && membershipFilters.includes(e?.membershipId))
        : true
    );
  }, [cohortfilteredMembers, membershipFilters]);

  const searchedMembers = useMemo(
    () =>
      searchTerm
        ? membershipfilteredMembers?.filter(i =>
            [`${i.user.firstName} ${i.user.lastName}`, i.user.lastName, i.user.email].some(
              v =>
                searchTerm &&
                !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
            )
          ) || []
        : membershipfilteredMembers,
    [searchTerm, membershipfilteredMembers]
  );

  const handleClick = (user?: User) => {
    setSearchTerm(user ? `${user.firstName} ${user.lastName}` : '');
    setSelectedMember(user ? user.id : undefined);
  };

  const handleSubscriptionUpdate = (data?: SubscriptionParams) => {
    if (searchedMembers && data) {
      update({
        subscriptionIds: searchedMembers
          .map(m =>
            m.enrolments
              .filter(e => ActiveStatus.includes(e.status) && membershipFilters.includes(e.membershipId))
              .map(e => e.subscriptionId)
          )
          .flat(),
        data: data,
      });
    }
    setSubscriptionUpdateOpen(false);
  };

  if (!schoolMembers) {
    return <></>;
  }

  return (
    <Grid container my={6} mb={24} maxWidth={1200} justifyContent={'center'}>
      <MemberModal open={memberModalOpen} onClose={() => setMemberModalOpen(false)} />
      <UpdateSubscriptionModal open={subscriptionUpdateOpen} onClose={handleSubscriptionUpdate} type="pause" />
      <Box>
        <Box mt={12}>
          {!!membershipFilters?.length && !!searchedMembers?.length && (
            <Box width={cardWidth} flexWrap={'wrap'} justifyContent={'space-between'} textAlign={'center'}>
              <Button onClick={() => setSubscriptionUpdateOpen(true)}>
                <PauseCircleOutlineIcon sx={{ mr: 2 }} />
                {'Pause memberships for selected members'}
              </Button>
            </Box>
          )}
        </Box>
        <Box display={'flex'} sx={{ width: cardWidth }}>
          <IconButton sx={{ height: 38, width: 38, mt: 4 }} onClick={e => setAnchor(e.currentTarget)}>
            <TuneIcon sx={{ color: school.priColor }} />
          </IconButton>

          {cohorts && memberships && (
            <MemberFilter
              cohorts={cohorts}
              memberships={memberships}
              cohortFilters={cohortFilters}
              onCohortChange={setCohortFilters}
              membershipFilters={membershipFilters}
              onMembershipChange={setMembershipFilters}
              anchor={anchor}
              onClose={() => setAnchor(undefined)}
            />
          )}
          <TextField
            autoComplete="off"
            value={searchTerm}
            onChange={({ currentTarget }) => {
              setSearchTerm(currentTarget.value.toLowerCase());
            }}
            sx={{ width: cardWidth }}
            InputProps={{
              endAdornment: (
                <>
                  {selectedMember && (
                    <InputAdornment position="start">
                      <IconButton sx={{ mr: -4 }} onClick={() => handleClick()}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                  <InputAdornment position="end">
                    <IconButton sx={{ mr: -4 }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Box>
        {!!filtersApplied && (
          <Box display={'flex'} width={1} justifyContent={'center'}>
            <Button
              variant="text"
              onClick={() => {
                setCohortFilters([]);
                setMembershipFilters([]);
              }}
            >
              Reset filters
            </Button>
          </Box>
        )}
      </Box>
      {memberResponse.data && (
        <Grid container my={6} mb={24} maxWidth={1200} justifyContent={'center'}>
          <MemberCard user={memberResponse.data.user} member={memberResponse.data} />
          {memberResponse.data.user?.children.map(c => (
            <MemberCard key={c.id} member={memberResponse.data.children.find(i => i.userId === c.id)} user={c} />
          ))}
        </Grid>
      )}
      <Grid container my={6} mb={24} maxWidth={1200} justifyContent={'center'} alignContent={'center'}>
        {!memberResponse.data && (
          <>
            <AddButton sx={{ m: 3, width: cardWidth }} label={'Add member'} onClick={() => setMemberModalOpen(true)} />
            {searchedMembers?.map((m, idx) => (
              <Box key={idx} sx={{ m: 3 }}>
                <UserButton member={m} onClick={() => handleClick(m.user)} />
              </Box>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

import { Box, Button, Checkbox, Popover, Typography } from '@mui/material';
import { Cohort } from 'hooks/useCohorts';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Membership } from 'hooks/useMemership';
import { useRef } from 'react';

export type Filters = { cohorts: Record<number, boolean> };

type Props = {
  anchor?: Element;
  cohorts: Cohort[];
  memberships: Membership[];
  cohortFilters: number[];
  onCohortChange: (value: number[]) => void;
  membershipFilters: number[];
  onMembershipChange: (value: number[]) => void;
  onClose: () => void;
};

export const MemberFilter = ({
  anchor,
  cohorts,
  memberships,
  cohortFilters,
  onCohortChange,
  membershipFilters,
  onMembershipChange,
  onClose,
}: Props) => {
  const { cardWidth } = useCustomTheme();
  const ref = useRef(null);

  const handleCohortChange = (key: number, value: boolean) => {
    const ids = value ? cohortFilters.concat([key]) : cohortFilters.filter(i => i !== key);
    onCohortChange(ids);
  };

  const handleMembershipChange = (key: number, value: boolean) => {
    const ids = value ? membershipFilters.concat([key]) : membershipFilters.filter(i => i !== key);
    onMembershipChange(ids);
  };

  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box ref={ref} sx={{ width: cardWidth, p: 4 }}>
        <Typography>Grades</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {cohorts?.map(c => (
            <Box key={c.id} display="flex" m={0.5}>
              <Checkbox
                checked={cohortFilters.includes(c.id)}
                onChange={e => handleCohortChange(c.id, e.currentTarget.checked)}
              />
              <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                {c.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography mt={2}>Memberships</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'membership')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Typography mt={2}>Group Memberships</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'group_membership')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Typography mt={2}>Class Passes</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'class_pass')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box width={1} textAlign={'end'} mt={2}>
          <Button
            variant="text"
            onClick={() => {
              onCohortChange([]);
              onMembershipChange([]);
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
